export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Alsportcheck',
    description:
      'Your game partner',
    buttonLabel: 'Get Started Now!',
    imgStart: '',
    img: require('../../images/logo.jpg'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Real time Sport News',
    description:
      "Stay in the game with real-time sports news and event updates, ensuring you catch every pivotal moment.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/alsportcheck1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Main Features',
    headline:
      'Event Details',
    description:
      "Instantly fetch event details, schedules, and real-time results.",
    // buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/alsportcheck2.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: 'Main Features',
    headline: 'Multiple Sports',
    description:
      'Covering a spectrum of sports and leagues – football, basketball, tennis, baseball, and more.',
    // buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/alsportcheck3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Main Features',
    headline: 'Personalized Contents',
    description:
      "Tailored experience – personalized recommendations based on your interests.",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/alsportcheck4.png'),
    alt: 'Vault',
    start: ''
  };
  // export const homeObjSix = {
  //   primary: false,
  //   lightBg: true,
  //   lightTopLine: false,
  //   lightText: false,
  //   lightTextDesc: false,
  //   topLine: 'Ultimate companion',
  //   headline:
  //     'DribblePro',
  //   description:
  //     "For sports enthusiasts, event aficionados, and anyone craving real-time event updates. Whether it's the latest game progress or instant event results, DribblePro is your ultimate smart companion!",
  //   // buttonLabel: 'View Details',
  //   imgStart: 'start',
  //   img: require('../../images/img5.png'),
  //   alt: 'Vault',
  //   start: 'true'
  // };